import {StepsManagerService} from '../services/StepsManagerService';
import {StepId} from '../../components/Checkout/CheckoutSteps/types';
import {BIService} from '../services/BIService';
import {CheckoutService} from '../services/CheckoutService';
import {StepsManagerStoreProps} from '../../types/app.types';
import {shouldShowGiftCardSection} from '../utils/shouldShowGiftCardSection';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {NavigationService} from '../services/NavigationService';

export class StepsManagerStore {
  private readonly stepsManagerService: StepsManagerService;
  private readonly biService: BIService;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly navigationService: NavigationService;
  private readonly updateComponent: () => void;

  constructor({
    stepsManagerService,
    biService,
    checkoutService,
    checkoutSettingsService,
    navigationService,
    updateComponent,
  }: {
    stepsManagerService: StepsManagerService;
    biService: BIService;
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    navigationService: NavigationService;
    updateComponent: () => void;
  }) {
    this.stepsManagerService = stepsManagerService;
    this.biService = biService;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.navigationService = navigationService;
    this.updateComponent = updateComponent;
  }

  private readonly sendEditStepClickedBIEvent = (stepId: StepId): void => {
    const previousStepName = this.stepsManagerService.getLastStepInQueue();
    this.biService.sendEditStepClicked(this.checkoutService.checkout, stepId, previousStepName);
  };

  private readonly goToNextStep = (): void => {
    this.stepsManagerService.goToNextStep();
    this.updateComponent();
  };

  private readonly updateStepOnStage = (stepIndex: number, stepId: StepId | null): void => {
    this.stepsManagerService.updateStepOnStage(stepIndex, stepId);
    this.updateComponent();
  };

  private readonly shouldDisplayExpressCheckout = (): boolean => {
    return this.stepsManagerService.getActiveStep().stepIndex === 0;
  };

  private readonly updateActiveStepId = (stepId: StepId): void => {
    this.stepsManagerService.updateActiveStepId(stepId);
    this.updateComponent();
  };

  private readonly sendStageExpandedBIEvent = (stepId: StepId): void => {
    this.biService.sendStageExpanded(
      shouldShowGiftCardSection({
        checkoutSettingsService: this.checkoutSettingsService,
        checkoutService: this.checkoutService,
      }),
      this.checkoutService.checkout,
      stepId,
      !this.stepsManagerService.isStepInQueue(stepId),
      this.navigationService
    );
  };

  public toProps(): StepsManagerStoreProps {
    return {
      sendStageExpandedBIEvent: this.sendStageExpandedBIEvent,
      updateStepOnStage: this.updateStepOnStage,
      sendEditStepClickedBIEvent: this.sendEditStepClickedBIEvent,
      shouldDisplayExpressCheckout: this.shouldDisplayExpressCheckout(),
      updateActiveStepId: this.updateActiveStepId,
      activeStep: this.stepsManagerService.getActiveStep(),
      goToNextStep: this.goToNextStep,
    };
  }
}
